@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.PoppinsExtraLight {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 200 !important;
}
.PoppinsLight {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300 !important;
}
.PoppinsRegular {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  /* background-image: url(https://images.pexels.com/photos/1264000/pexels-photo-1264000.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1); */

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
:root {
  --primary: #4b8804;
  /* --primary: #4b8804;
  --secondary: #592c91;
  --grey: #7787ab;
  --background: #f8f8fb; */
}

.bg-image {
  background-image: url('../src/assets/images/bg5.png');
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

#root {
  display: grid;
  place-items: center;
}

.my-width-350 {
  width: 95%;
  max-width: 350px;
}
.my-width-700 {
  width: 95%;
  max-width: 700px;
  padding-block-end: 10rem;
}
.my-width-100vw {
  /* width: 95%; */
  max-width: 100vw;
}

.form-container {
  background-color: #f8f8fb;
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
}

.shadowed {
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
}

.col {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.row {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding-block: 0.5em;
  padding-inline: 0.2em;
}
.group-row {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  width: 100%;
  padding-block: 0.5em;
  padding-inline: 0.2em;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.padding1 {
  padding: 1em;
}
.margin1 {
  margin: 1em;
}

.margin-block-1 {
  margin: 1em 0;
}

.actions-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-left: auto;
}

.divider {
  background-color: cornflowerblue;
  color: #fff;
  font-size: 2rem;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.success-message {
  background: #06a906;
  border-radius: 4px;
  color: white;
  text-align: center;
  width: fit-content;
  margin: auto;
}

.pagination-container {
  display: flex;
}

.pagination-container > nav {
  margin-inline: auto;
  margin-top: 1em;
}

@media print {
  .page-break {
    page-break-before: always; /* or "after" if you want the break after the element */
  }
}

.text-align-center {
  text-align: center;
}

/* @media screen and (max-width: 500px) {
  .row {
    flex-direction: column;
  }
  .actions-container {
    margin-left: auto;
    margin-right: auto;
  }

  .padding1 {
    padding: 0.5em;
  }
} */

/* when in landscape */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-logo {
  animation: slideInFromRight 2s ease-in forwards;
}

/* When in portrait */
@media screen and (orientation: portrait) {
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-logo {
    animation: slideInFromBottom 2s ease-in forwards;
  }
}

.scoring-card {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;

  padding: 1em;
}

.basic-glass {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  max-height: 1900px; /* Adjust this value as needed */
  overflow: hidden;
  padding: 1em;
}

.basic-glass p {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 9.5em; /* Adjust based on line height and number of lines */
}

.container-with-bg {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../src/assets/images/bg5.png');
  padding-bottom: 10rem;
}

.judge-view-main-container {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../src/assets/images/bg5.png');
}

.judge-view-container {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.client-view-group-container {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  padding: 1rem;
}

.judge-now-container {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.client-view-contestant-row-container {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 16px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  padding: 1em;
  display: flex;
  flex-direction: column;
  margin-block: 1em;
}
.client-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url('../src/assets/images/bg5.png');
  overflow-x: auto;
}

.hero-section {
  background-image: url('../src/assets/images/bg5.png');
}

.hero-section-glass-container {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  padding: 1rem;
}
.testimonial-glass-container {
  background: rgba(255, 255, 255, 0.9);
  /* box-shadow: 0 2px 8px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  padding: 1rem;
}

/* STYLES FROM CLASSES FROM ELEMENTS */
.css-pazuet {
  min-width: none !important;
}
.MuiSlider-valueLabelOpen,
.css-g2kqbz-MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
  background: #4b8804 !important;
  border-radius: 4px !important;
}
