.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  z-index: 100;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 1rem;
  transition: padding 3s ease-in-out 1s;
  /* height: 90%; */
}

@media (min-width: 768px) {
  .modal {
    left: 50%;
    transform: translateX(-50%);
    width: 40rem;
  }
}

@media screen and (max-width: 530px) {
  .modal {
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 0.5rem;
    max-height: 100%;
  }
}
